import React from "react";

const Controls = (props) => {
	return (
		<div className="row align-center justify-center row-control-img">
			<div className="container  align-center justify-start">
				<div className="row-responsive">
					<div className="column justify-center align-start half">
						<h2>{props.title}</h2>
					</div>
					<div className="column justify-center align-center">
						<div className="row justify-end align-end">
							{
								<React.Fragment>
									<input
										className="input padding-big"
										type="text"
										placeholder="Marca o SKU"
										name="every"
										onKeyDown={props.keypress}
										onChange={props.inputChange}
										value={props.valueInput}
									></input>
									&nbsp;
									<button type="button" className="btn btn-primary  padding-big btn-small color-white" onClick={props.searchHandler}>
										<i className="fas fa-search"></i>
										&nbsp; Buscar
									</button>
									&nbsp;
									{props.exporting ? (
										<div className="btn btn-primary btn-small padding-big full justify-center color-white">
											<i className="fas fa-spinner animation-rotate"></i>
										</div>
									) : (
										<button type="button" className="btn btn-primary btn-small padding-big full  color-white" onClick={props.toExportHandler}>
											<i className={`fas fa-file-excel font-text`} />
											&nbsp; Exportar
										</button>
									)}
									&nbsp;
									<button type="button" className="btn btn-primary  padding-big btn-small color-white" onClick={props.uploadHandler}>
										<i className={`fas fa-upload font-text`} />
										&nbsp; Subir
									</button>
								</React.Fragment>
							}
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};
export default Controls;
