import React, { useEffect, useState } from "react";
import moment from "moment-timezone";
import { FaCheck, FaTimes } from "react-icons/fa";

const TableUpload = (props) => {
  const [logList, setLogList] = useState([]);

  useEffect(() => {
    if (logList !== props.dataset) {
      setLogList(props.dataset);
    }
  }, [props.dataset, logList]);

  return (
    <div className="container">
      <div className="card-table">
        <div className="table-responsive column">
          <table>
            <thead>
              <tr className="text-left">
                <th className="date text-center th-options-small">Fecha</th>
                <th className="date text-center th-options-small">Status</th>
                <th className="date text-center th-options-small">
                  Hora Inicio
                </th>
                <th className="date text-center th-options-small">Hora Fin</th>
                <th className="date text-center th-options-small">Error</th>
                <th className="date text-center th-options-small">Detalles</th>
              </tr>
            </thead>
            <tbody>
              {props.isLoading ? (
                <tr>
                  <td colSpan="5" className="date text-center th-options-small">
                    <i className="fas fa-spinner animation-rotate"></i>
                  </td>
                </tr>
              ) : logList.length > 0 ? (
                logList
                  .slice((props.page - 1) * 20, props.page * 20)
                  .map((log, key) => {
                    return (
                      <tr key={key}>
                        <td className="date text-center th-options-small">
                          {moment(log.fecha)
                            .tz("America/Mexico_City")
                            .format("DD/MM/YYYY")}
                        </td>
                        <td className="date text-center th-options-small">
                          {log.status}{" "}
                        </td>
                        <td className="date text-center th-options-small">
                          {log.fecha_inicio !== null
                            ? moment(log.fecha_inicio)
                                .tz("America/Mexico_City")
                                .format("HH:mm")
                            : ""}
                        </td>
                        <td className="date text-center th-options-small">
                          {log.fecha_fin !== null
                            ? moment(log.fecha_fin)
                                .tz("America/Mexico_City")
                                .format("HH:mm")
                            : ""}
                        </td>
                        <td className="date text-center">{log.error} </td>
                        <td
                          className="date text-right"
                          style={{ display: "flex" }}
                        >
                          {(() => {
                            let actualizado = "-";
                            let noActualizado = "-";
                            let total = "-";
                            if (
                              log.comentarios != null &&
                              log.comentarios !== ""
                            ) {
                              const valores = log.comentarios.split(" ");
                              total = valores[1];
                              actualizado = valores[3];
                              noActualizado = valores[6];
                            }

                            return (
                              <>
                                <div> Total:{total}</div>
                                <div>
                                  <FaCheck style={{ color: "green" }} />{" "}
                                  {actualizado}
                                </div>
                                <div>
                                  <FaTimes style={{ color: "red" }} />{" "}
                                  {noActualizado}
                                </div>
                              </>
                            );
                          })()}
                        </td>
                      </tr>
                    );
                  })
              ) : (
                <tr>
                  <td colSpan="5" className="date text-center th-options-small">
                    No Existen registros
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default TableUpload;
