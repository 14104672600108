import React from "react";
import Helmet from "react-helmet";
import Chart from "chart.js";
import moment from "moment-timezone";
import NumberFormat from "react-number-format";

import {Consumer} from "../../context";
import request from "../../tool/request";
import {URL_API} from "../../contants/url_api";

import Sidebar from "../../components/Sidebar";
import ConfigSideBar from "../../components/ConfigSideBar";
import Header from "../../components/Header";

import Card from "../../components/Card";

const Request = new request();

class Perfil extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			user: this.props.context.loadUser(),
			ratings: null,
			total_visits: 0,
			visitList: [],
		};
	}

	async componentDidMount() {
		await this.getData();
		await this.getVisits();
		await this.setVisitsChart();
	}

	async getData() {
		let data = {access_token: this.state.user.access_token};

		let response = await Request.get(URL_API + "/users/me", data);

		if (!response.error) {
			await this.setState({
				ratings: response.usuario.seller_reputation.transactions.ratings,
				ventasTotales:  response.usuario.seller_reputation.transactions.total,
			});
		} else {
			await this.setState({
				ratings: {},
				ventasTotales:  0,
			});
		}
		
	}

	async getVisits() {
		let data = {user_id: this.state.user.user_id};

		let response = await Request.get(URL_API + "/users/get/visits", data);
		console.log(response);
		if (!response.error) {
			await this.setState({
				total_visits: response.total_visits || 0,
				visitList: response.results || [],
			});
		} else {
			await this.setState({
				total_visits: 0,
				visitList: [],
			});
		}

		
	}

	async setVisitsChart() {
		var element = document.getElementById("lineChart");

		let labelArray = [];
		let totalArray = [];

		let dataset = this.state.visitList;

		dataset.map((visit) => {
			let day = moment(visit.date)
				.tz("America/Mexico_City")
				.format("DD-MM-YYYY");
			labelArray.push(day);
			totalArray.push(visit.total);
		});

		var lineChart = new Chart(element, {
			type: "bar",
			data: {
				labels: labelArray,
				datasets: [
					{
						data: totalArray,
						borderColor: "#0061b6",
						borderWidth: 2,
						pointHitRadius: 3,
						pointBorderWidth: 3,
						backgroundColor: "#0061b6",
						
					},
				],
			},
			options: {
				scales: {
					yAxes: [
						{
							stacked: true,
						},
					],
				},
				title: {
					display: true,
					text: "Visitas en los últimos 7 días",
				},
				legend: {
					display: false,
				},
			},
		});
	}

	render() {
		return (
			<div className="profile column">
				<Helmet>
					<title>Plataforma de sincronización</title>
				</Helmet>

				<Header />
				<div className="row">
					<div className="column sidebar" id="sidebar">
						<Sidebar />
					</div>

					<div className="column align-center">
						<div className="white-space-24"></div>
						<div className=" container column">
							<div className="row-responsive full justify-center align-center">
								<div className="column justify-center align-center">
									<Card className="justify-center align-center">
										<div className="row align-center justify-center">
											<div className="column">
												<div className="row align-center justify-center">
													<div className="white-space-8"></div>
												</div>
												<div className="row align-center justify-center">
													<h1>
														Ventas:
														<NumberFormat
															value={
																this.state.ventasTotales
																	? this.state.ventasTotales
																	: 0
															}
															displayType={"text"}
															thousandSeparator={true}
															decimalScale={0}
															fixedDecimalScale={true}
														/>
													</h1>
												</div>
												<div className="row align-center justify-center">
													<h5>Totales</h5>
												</div>
												<div className="row align-center justify-center">
													<div className="white-space-8"></div>
												</div>
											</div>
										</div>
									</Card>
								</div>
								<div className="column justify-center align-center">
									<Card className="justify-center align-center">
										<div className="row align-center justify-center">
											<div className="column">
												<div className="row align-center justify-center">
													<div className="white-space-8"></div>
												</div>
												<div className="row align-center justify-center">
													<h1>
														Visitas:
														<NumberFormat
															value={
																this.state.total_visits
																	? this.state.total_visits
																	: 0
															}
															displayType={"text"}
															thousandSeparator={true}
															decimalScale={0}
															fixedDecimalScale={true}
														/>
													</h1>
												</div>
												<div className="row align-center justify-center">
													<h5>Últimos 7 días</h5>
												</div>
												<div className="row align-center justify-center">
													<div className="white-space-8"></div>
												</div>
											</div>
										</div>
									</Card>
								</div>
								<div className="column">
									<Card className="justify-center align-center">
										<div className="row align-center justify-center">
											<div className="column align-center justify-center">
												<h2>Reputación</h2>
											</div>
											<div className="column">
												<div className="row">
													<div className="column align-center justify-center">
														<h3>
															<i className="fas fa-plus color-success"></i>
														</h3>
													</div>
													<div className="column justify-center align-center">
														<h3>
															{this.state.ratings
																? `${
																		this.state.ratings.positive * 100
																  } %`
																: 0}
														</h3>
													</div>
												</div>
												<div className="row">
													<div className="column align-center justify-center">
														<h3>
															<i className="fas fa-minus color-warning"></i>
														</h3>
													</div>
													<div className="column justify-center align-center">
														<h3>
															{this.state.ratings
																? `${
																		this.state.ratings.neutral * 100
																  } %`
																: 0}
														</h3>
													</div>
												</div>
												<div className="row">
													<div className="column align-center justify-center">
														<h3>
															<i className="fas fa-times color-danger"></i>
														</h3>
													</div>
													<div className="column justify-center align-center">
														<h3>
															{this.state.ratings
																? `${
																		this.state.ratings.negative * 100
																  } %`
																: 0}
														</h3>
													</div>
												</div>
											</div>
										</div>
									</Card>
								</div>
							</div>
							<div className="row">
								<div className="white-space-24"></div>
							</div>

							<div className="row-responsive">
								<div className="profile-card column">
									<div className="row-responsive input-form-content">
										<div className="column line-chart full">
											<canvas
												className="line"
												id="lineChart"
												width="100%"
												height="35%"
											></canvas>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<ConfigSideBar />
			</div>
		);
	}
}

export default Consumer(Perfil);
