/**
 *  routes.jsx
 *  @version: 1.0.0
 *  @author: DWIT MÉXICO
 *  @description: Manejo de todas las rutas de la aplicación
 * 	@process: 4
*/

import React, { Component } from 'react';
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";

// context
import { GlobalContext } from './context';

// Web Pages
import Login from './views/pages/Login';
import DownloadPage from './views/pages/DownloadPage';
import UpdatePage from './views/pages/UpdatePage';
import Inventory from './views/pages/Inventory';
import Home from './views/pages/Home';
import Image from './views/pages/Image';
import Sucursales from './views/pages/Sucursales';
import Atributos from './views/pages/Atributos';




class Routes extends Component {
    render() {

        const PrivateRoute = ({component: Component, ...rest}) => (
            <Route {...rest} render={props => {
                const user = window.localStorage.getItem('user');
                if(user) {
                    return <Component {...props} />
                } else {
                    return <Redirect  to="/login"/>
                }
            }}/>
            );
        return (
            <GlobalContext>
                <BrowserRouter>
                    <div className="flex main">
                        <div className="column full">
                            <Switch>
                                <Route path="/login" exact component={Login} />
                                <PrivateRoute path="/" exact component = {Home} />
                                <PrivateRoute path="/update" exact component = {UpdatePage} />
                                <PrivateRoute path="/inventory" exact component = {Inventory} />
                                <PrivateRoute path="/download" exact component = {DownloadPage} />
                                <PrivateRoute path="/imagen" exact component = {Image} />
                                <PrivateRoute path="/stores" exact component = {Sucursales} />
                                <PrivateRoute path="/attribute" exact component = {Atributos} />
                            </Switch>
                        </div>
                    </div>
                </BrowserRouter>
            </GlobalContext>
        );
    }    
}

export default Routes;
