import React from "react";

class Mapa extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			address: "",
		};
	}

	componentDidMount() {
		window.initMap = this.initMap.bind(this);

		const script = document.createElement("script");
		script.async = true;
		script.defer = true;
		script.src =
			"https://maps.googleapis.com/maps/api/js?key=AIzaSyDlbh8UchF3kXm7yl9XoDA24Tv3ZpnDRGE&callback=initMap";
		document.head.appendChild(script);
	}

	async componentDidUpdate(prevProps, prevState) {
		if (prevProps.address !== this.props.address) {
			await this.setState({address: this.props.address});
		}
	}

	initMap() {
		const props = this.props;
		let coord = {lat: 21.161908, lng: -86.8515279};

		let map = new window.google.maps.Map(document.getElementById("map"), {
			center: coord,
			zoom: 15,
			disableDefaultUI: true,
			zoomControl: true,
		});

		let marker = new window.google.maps.Marker({map});
		let geocoder = new window.google.maps.Geocoder();

		function placeMarker(location) {
			marker.setPosition(location);
			if (props.setCoordinates) {
				props.setCoordinates(location.lat(), location.lng());
			}
			getLocation(geocoder, location);
		}

		map.addListener("click", function (e) {
			placeMarker(e.latLng);
		});

		props.validateGPS.onclick = () => {
			getCoordinates(`${this.state.address} mexico`);
		};

		function getLocation(geocoder, location) {
			geocoder.geocode({latLng: location}, function (results, status) {
				if (status === "OK") {

					const data = results[0];
					const coord = results[0].geometry.location;
					console.log(data);

					const address = data.address_components.find(element => element.types[0] === 'route');
					const street = data.address_components.find(element => element.types[0] === 'street_number');
					const number = data.address_components.find(element => element.types[0] === 'political');

					if (data) {
						props.setAddress({
							address_line: `${address ? address.short_name : ""  } ${number ? number.short_name : "" } , ${street ? street.short_name : "" }`,
							latitude: coord.lat(), 
							longitude: coord.lng(),
						});
					}
				} else {
					alert("Geocoder failed due to: " + status);
				}
			});
		}

		function getCoordinates(address) {
			geocoder.geocode({address: address}, function (results, status) {
				if (status === "OK") {
					map.setCenter(results[0].geometry.location);
					marker.setPosition(results[0].geometry.location);
					if (props.setCoordinates) {
						props.setCoordinates(
							results[0].geometry.location.lat(),
							results[0].geometry.location.lng()
						);
					}
					const data = results[0];
					const coord = results[0].geometry.location;

					const address = data.address_components.find(element => element.types[0] === 'route');
					const street = data.address_components.find(element => element.types[0] === 'street_number');
					const number = data.address_components.find(element => element.types[0] === 'political');

					if (data) {
						props.setAddress({
							address_line: `${address ? address.short_name : ""  } ${number ? number.short_name : "" } , ${street ? street.short_name : "" }`,
							latitude: coord.lat(), 
							longitude: coord.lng(),
						});
					}
				} else {
					alert("Geocoder failed due to: " + status);
				}
			});
		}
	}

	render() {
		return (
			<div id="map" ref="map" style={{width: "100%", height: "300px"}}></div>
		);
	}
}

export default Mapa;
