import React, {useEffect, useState} from "react";

const TableImage = (props) => {
	const [dataset, setDataSet] = useState([]);

	useEffect(() => {
		if (dataset !== props.dataset) {
			setDataSet(props.dataset);
		}
	}, [props.dataset]);

	return (
		<div className="container">
			<div className="card-table">
				<div className="table-responsive column">
					<table>
						<thead>
							<tr className="text-left">
								<th className="date text-center th-options-mini">ID</th>
								<th className="date text-center th-options-mini">SKU</th>
								<th className="date text-center th-options">Título</th>
								<th className="date text-center th-options">Status</th>
							</tr>
						</thead>
						<tbody>
							{props.isLoading ? (
								<tr>
									<td colSpan="5" className="date text-center th-options-small">
										<i className="fas fa-spinner animation-rotate"></i>
									</td>
								</tr>
							) : dataset.length > 0 ? (
								dataset.slice((props.page - 1) * 20, props.page * 20).map((data, key) => {
									return (
										<tr key={key}>
											<td className="date text-center th-options-small">{data.id_publicacion}</td>
											<td className="date text-center th-options-small">{data.SELLER_SKU}</td>
											<td className="date text-center">{data.title}</td>
											<td className="date text-center">{data.status}</td>
										</tr>
									);
								})
							) : (
								<tr>
									<td colSpan="5" className="date text-center th-options-small">
										No Existen registros
									</td>
								</tr>
							)}
						</tbody>
					</table>
				</div>
			</div>
		</div>
	);
};

export default TableImage;
