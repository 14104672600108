import React from "react";
import Helmet from "react-helmet";
import cogoToast from "cogo-toast";

import {Consumer} from "../../context";
import Sidebar from "../../components/Sidebar";
import ConfigSideBar from "../../components/ConfigSideBar";
import Header from "../../components/Header";
import TableDownload from "../../components/Tables/TableDownload";
import Paginator from "../../components/Paginator";
import Modal from "../../components/modal/DinamicModal";
import ScheduleModal from "../../components/modal/ScheduleModal";

import request from "../../tool/request";
import {URL_API} from "../../contants/url_api";
import Controls from "../../components/Controls/Controls";

const Request = new request();

class DownloadPage extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			log: [],
			user: this.props.context.loadUser(),
			schedule: {},
			loading: false,
			page: 1,
		};
	}

	componentDidMount() {
		this.loadLog();
	}

	async loadLog() {
		await this.setState({loading: true});
		const data = {user_id: this.state.user.user_id, type: 1};
		const response = await Request.get(
			URL_API + "/publication/get/log/list",
			data
		);
		if (!response.empty && !response.error) {
			await this.setState({log: response.data});
		} else {
			await this.setState({log: []});
		}
		await this.setState({loading: false});
	}

	async configHandler() {
		const data = {
			user_id: this.state.user.user_id,
			type: 1,
		};
		const response = await Request.get(URL_API + "/schedule/get", data);

		if (response.error || response.empty) {
			await this.setState({schedule: {}, isOpen: true});
		} else {
			await this.setState({schedule: response.data, isOpen: true});
		}

		let modal = document.getElementById("downloadModal");

		if (modal) {
			modal.classList.remove("hide-dinamic-modal");
			modal.classList.add("show-modal");
		}
	}

	async saveNewSchedule(event) {
		event.preventDefault();
		const data = {
			...this.state.schedule,
			type: 1,
			user_id: this.state.user.user_id,
			access_token: this.state.user.access_token,
			refresh_token: this.state.user.refresh_token,
			expire: this.state.user.expire,
		};

		const response = await Request.post(URL_API + "/schedule/save", data);
		if (response.error) {
			cogoToast.error("No fue posible guardar la programación.", {
				position: "bottom-right",
			});
		} else {
			cogoToast.success("Programación guardada con exito.", {
				position: "bottom-right",
			});
			await this.setState({schedule: {}, isOpen: false});
			let modal = document.getElementById("downloadModal");
			if (modal) {
				modal.classList.add("hide-dinamic-modal");
				modal.classList.remove("show-modal");
			}
		}
	}

	async updateData(data) {
		await this.setState(data);
	}

	async selectPage(page) {
		await this.setState({page: page});
	}

	async newDownload() {
		const data = {
			user_id: this.state.user.user_id,
			access_token: this.state.user.access_token,
			refresh_token: this.state.user.refresh_token,
			expire: this.state.user.expire,
		};

		const response = await Request.get(
			URL_API + "/publication/download/data",
			data
		);

		if (response.error) {
			cogoToast.error("No fue fue posible iniciar la descarga.", {
				position: "bottom-right",
			});

			return;
		} else {
			cogoToast.loading("Ha iniciado la descarga de los datos.", {
				position: "bottom-right",
			});
			await this.loadLog();
		}
	}

	render() {
		return (
			<div className="Home column">
				<Helmet>
					<title>Download | Plataforma de sincronización</title>
				</Helmet>

				<Header />
				<div className="row">
					<div className="column sidebar" id="sidebar">
						<Sidebar />
					</div>

					<div className="column align-center ">
						<div className="row">
							<div className="white-space-24"></div>
						</div>
						<Controls
							icon={"fa-download"}
							title="Descargar Datos"
							lblBtn="Descargar"
							btnHandlerVisible={true}
							btnConfigVisible={true}
							handler={this.newDownload.bind(this)}
							configHandler={this.configHandler.bind(this)}
							lblConfigBtn={""}
						/>
						<div className="row">
							<div className="white-space-32"></div>
						</div>
						<div className="row justify-center">
							<TableDownload
								dataset={this.state.log}
								isLoading={this.state.loading}
								page={this.state.page}
							/>
						</div>
						<div className="row">
							<div className="white-space-16"></div>
						</div>
						<div className="row">
							<Paginator
								pages={Math.ceil(this.state.log.length / 20)}
								setPage={this.selectPage.bind(this)}
							/>
						</div>
					</div>
				</div>
				<Modal
					idModal={"downloadModal"}
					sizeModalContainer={"mini"}
					title={"Programar Descarga"}
					btnTextSuccess={"Guardar"}
					success={this.saveNewSchedule.bind(this)}
					showBtnSuccess={true}
					btnTextCancel={"Cancelar"}
					showBtnCancel={true}
					loadingSave={false}
				>
					<ScheduleModal
						isOpen={this.state.isOpen}
						updateData={this.updateData.bind(this)}
						schedule={this.state.schedule}
					/>
				</Modal>
				<ConfigSideBar />
			</div>
		);
	}
}

export default Consumer(DownloadPage);
