import React, {useState, useEffect} from "react";
import Helmet from "react-helmet";
import cogoToast from "cogo-toast";
import XLSX from "xlsx";

import {Consumer} from "../../context";
import Sidebar from "../../components/Sidebar";
import ConfigSideBar from "../../components/ConfigSideBar";
import Header from "../../components/Header";
import TableImage from "../../components/Tables/TableImage";
import Paginator from "../../components/Paginator";
import Modal from "../../components/modal/DinamicModal";
import ModalZoom from "../../components/modal/modalZoom";
import UploadXLS from "../../components/modal/UploadXLS";
import ControlUpload from "../../components/Controls/ControlUpload";

import {URL_API} from "../../contants/url_api";
import request from "../../tool/request";
import exportToExcel from "../../tool/excel";

const Request = new request();

const Inventory = (props) => {
	const [user, setUser] = useState(props.context.user);
	const [dataset, setDataset] = useState([]);
	const [inputValue, setInputValue] = useState("");
	const [loading, setLoading] = useState(false);
	const [exporting, setExporting] = useState(false);
	const [page, setPage] = useState(1);
	const [fileName, setFileName] = useState("");
	const [listImg, setListImg] = useState([]);
	const [imgZoom, setImgZoom] = useState("");

	useEffect(() => {
		if (user !== props.context.user) {
			setUser(props.context.user);
		}
	}, [props.context.user]);

	function inputChange(event) {
		setInputValue(event.target.value);
	}

	async function selectPage(page) {
		setPage(page);
	}

	async function searchHandler() {
		if (inputValue != "") {
			setLoading(true);
			const data = {
				user_id: user.user_id,
				brand: inputValue,
			};
			const response = await Request.get(URL_API + "/publication/get/imagen", data);
			if (!response.empty && !response.error) {
				console.log(response.data);
				setDataset(response.data || []);
			} else {
				setDataset([]);
			}
			setLoading(false);
		}
	}

	async function keypress(event) {
		if (event.keyCode === 13) {
			searchHandler();
		}
	}

	async function uploadHandler() {
		let modal = document.getElementById("uploadModal");

		setFileName("");
		setListImg([]);
		if (modal) {
			modal.classList.remove("hide-dinamic-modal");
			modal.classList.add("show-modal");
		}
	}

	async function savePhoto(event) {
		event.preventDefault();
		const data = {
			data: listImg,
			user_id: user.user_id,
			access_token: user.access_token,
			refresh_token: user.refresh_token,
			expire: user.expire,
		};
		const response = await Request.post(URL_API + "/publication/save/imagen", data);

		if (response.error) {
			cogoToast.error("Hubo un error al guardar el listado.", {
				position: "bottom-right",
			});
		} else {
			cogoToast.loading("Ha iniciado la actualización de imagenes.", {
				position: "bottom-right",
			});
			let modal = document.getElementById("uploadModal");
			if (modal) {
				modal.classList.add("hide-dinamic-modal");
				modal.classList.remove("show-modal");
			}
		}
	}

	async function uploadFile(event) {
		if (event.target.files[0]) {
			const reader = new FileReader();
			const rABS = !!reader.readAsBinaryString;
			let nombre = event.target.files[0].name;

			if (rABS) {
				reader.readAsBinaryString(event.target.files[0]);
			} else {
				reader.readAsArrayBuffer(event.target.files[0]);
			}

			reader.onload = (e) => {
				const bstr = e.target.result;
				const wb = XLSX.read(bstr, {
					type: rABS ? "binary" : "array",
					bookVBA: true,
				});
				const wsname = wb.SheetNames[0];
				const ws = wb.Sheets[wsname];
				let data = XLSX.utils.sheet_to_json(ws);

				setListImg(data);
				setFileName(nombre);
			};
			event.target.value = null;
		}
	}

	async function toExportHandler(event) {
		if (dataset.length > 0) {
			try {
				setExporting(true);
				const buffer = await exportToExcel(dataset, ["TotalImg", "title", "BRAND", "TotalImg", "pictures"]);
				const bytes = new Uint8Array(buffer);
				const blob = new Blob([bytes]);
				const link = document.createElement("a");
				link.href = window.URL.createObjectURL(blob);
				const fileName = "ListadoImagenes.xlsx";
				link.download = fileName;
				link.click();
				setExporting(false);
			} catch (error) {
				console.log(error);
				setExporting(false);
			}
		}
	}

	function openZoom(img) {
		setImgZoom(img);
		let modal = document.getElementById("image-zoom-modal");
		if (modal) {
			modal.classList.remove("hide-zoom-modal");
			modal.classList.add("show-zoom-modal");
		}
	}

	return (
		<div className="Home column">
			<Helmet>
				<title>Imagenes | Plataforma de sincronización</title>
			</Helmet>
			<Header />
			<div className="row">
				<div className="column sidebar" id="sidebar">
					<Sidebar />
				</div>

				<div className="column align-center ">
					<div className="row">
						<div className="white-space-24"></div>
					</div>
					<ControlUpload
						title="Imagenes"
						valueInput={inputValue}
						inputChange={inputChange.bind(this)}
						searchHandler={searchHandler.bind(this)}
						keypress={keypress.bind(this)}
						uploadHandler={uploadHandler.bind(this)}
						toExportHandler={toExportHandler.bind(this)}
						exporting={exporting}
					/>
					<div className="row">
						<div className="white-space-32"></div>
					</div>
					<div className="row justify-center align-center">
						<TableImage dataset={dataset} isLoading={loading} page={page} openZoom={openZoom.bind(this)} />
					</div>
					<div className="row">
						<div className="white-space-16"></div>
					</div>
					<div className="row">
						<Paginator pages={Math.ceil(dataset.length / 20)} setPage={selectPage.bind(this)} />
					</div>
				</div>
			</div>
			<Modal
				idModal={"uploadModal"}
				sizeModalContainer={"mini"}
				title={"Buscar Archivo"}
				btnTextSuccess={"Guardar"}
				success={savePhoto.bind(this)}
				showBtnSuccess={true}
				btnTextCancel={"Cancelar"}
				showBtnCancel={true}
				loadingSave={false}
			>
				<UploadXLS fileName={fileName} uploadFile={uploadFile.bind(this)} />
			</Modal>
			<ModalZoom
				imgZoom={imgZoom}
				closeModal={(value) => {
					setImgZoom(value);
				}}
			/>

			<ConfigSideBar />
		</div>
	);
};

export default Consumer(Inventory);
