import React from "react";
import {Consumer} from "../context";

const Sidebar = (props) => {
	let state = {
		active: 0,
		menus: [
			{name: "Home", path: "/", icon: "fas fa-users"},
			{name: "Inventario", path: "/inventory", icon: "fas fa-box"},
			{name: "Descargar", path: "/download", icon: "fa-download"},
			{name: "Actualizar", path: "/update", icon: "fa-upload"},
			{name: "Imagenes", path: "/imagen", icon: "fa-image"},
			{name: "Atributos", path: "/attribute", icon: "fa-list-alt"},
			{name: "Sucursales", path: "/stores", icon: "fa-store"},

		],
	};

	return <div className="row">
		<div className="column content-side">
			<div className="white-space-16"></div>
			{state.menus.map((menu, key) => {
				return (
					<div className="panel maxHeight" key={key}>
						<a href={menu.path}>
							<div className="row item auto ">
								<i className={`fas ${menu.icon} font-regular`}></i>
								<span className="font-regular "> &nbsp; {menu.name}</span>
							</div>
						</a>
					</div>
				);
			})}
		</div>
	</div>;
};

export default Consumer(Sidebar);
