import React, { Component, createContext } from 'react';
import moment from "moment-timezone";
import Request from "../tool/request";
import {URL_API} from "../contants/url_api";

const request = new Request();

const Context = createContext();

class GlobalContext extends Component {
	constructor(props) {
        super(props);
        this.state = {
			user:{},
            setUser: this.setUser.bind(this),
			loadUser: this.loadUser.bind(this),
			logOut: this.logOut.bind(this),
        }
    }

    setUser(user) {
		window.localStorage.setItem('user', JSON.stringify(user));
		this.setState({user:user});
	}
	
	logOut(){
		window.localStorage.clear();
	}

	loadUser()
	{
		const user = JSON.parse(window.localStorage.getItem('user'));
		if (user) {
			this.setState({user:user})
		}
		return user;
	}
    
    async componentDidMount() {
		const user = JSON.parse(window.localStorage.getItem('user')); 
		if (user) {
			if (moment().tz('America/Mexico_City').format("YYYY-MM-DD HH:mm:ss") >= moment(user.expire).tz('America/Mexico_City').format("YYYY-MM-DD HH:mm:ss")) {

				const data = {
					refresh_token: user.refresh_token,
				}
				const response = await request.post(URL_API + "/users/refresh/token",data);
				
				if (response.error) {
					localStorage.clear();
					window.location = '/login';
				}
				else{
					console.log(response);
					user.access_token = response.access_token;
					user.refresh_token = response.refresh_token;
					user.expire = response.expire;
					await this.setUser(user);
				}
			}
			else{
				this.setState({user: user});
			}
		}
	}

	render() {
		return (
			<Context.Provider value={this.state}>
				{this.props.children}
			</Context.Provider>
		)
	}
}

const Consumer = (Component) => {
	return (props) => {
		return (
			<Context.Consumer>
				{context => <Component {...props} context={context} />}
			</Context.Consumer>
		)
	}
}

export {Consumer, GlobalContext};
