import React, {useState, useEffect} from "react";
import Helmet from "react-helmet";

import {Consumer} from "../../context";
import Sidebar from "../../components/Sidebar";
import ConfigSideBar from "../../components/ConfigSideBar";
import Header from "../../components/Header";
import TableInventory from "../../components/Tables/TableInventory";
import Paginator from "../../components/Paginator";

import request from "../../tool/request";
import {URL_API} from "../../contants/url_api";
import Controls from "../../components/Controls/ControlsInventario";

const Request = new request();

const Inventory = (props) => {
	const [user, setUser] = useState(props.context.user);
	const [dataset, setDataset] = useState([]);
	const [inputValue, setInputValue] = useState("");
	const [loading, setLoading] = useState(false);
	const [page, setPage] = useState(1);

	useEffect(() => {
		if (user !== props.context.user) {
			setUser(props.context.user);
		}
	}, [props.context.user]);

	function inputChange(event) {
		setInputValue(event.target.value);
	}

	async function selectPage(page) {
		setPage(page);
	}

	async function searchHandler() {
		if (inputValue.replace(" ", "") !== "") {
			setLoading(true);
			const data = {user_id: user.user_id, id: inputValue};
			const response = await Request.get(URL_API + "/publication/get/stock", data);
			if (!response.empty && !response.error) {
				setDataset(response.data);
			} else {
				setDataset([]);
			}
			setLoading(false);
		}
	}

	async function keypress(event) {
		if (event.keyCode === 13) {
			searchHandler();
		}
	}

	return (
		<div className="Home column">
			<Helmet>
				<title>Inventario | Plataforma de sincronización</title>
			</Helmet>

			<Header />
			<div className="row">
				<div className="column sidebar" id="sidebar">
					<Sidebar />
				</div>

				<div className="column align-center ">
					<div className="row">
						<div className="white-space-24"></div>
					</div>
					<Controls icon={"fa-download"} title="Inventario" valueInput={inputValue} inputChange={inputChange.bind(this)} searchHandler={searchHandler.bind(this)} keypress={keypress.bind(this)} />
					<div className="row">
						<div className="white-space-32"></div>
					</div>
					<div className="row justify-center align-center">
						<TableInventory dataset={dataset} isLoading={loading} page={page} />
					</div>
					<div className="row">
						<div className="white-space-16"></div>
					</div>
					<div className="row">
						<Paginator pages={Math.ceil(dataset.length / 20)} setPage={selectPage.bind(this)} />
					</div>
				</div>
			</div>
			<ConfigSideBar />
		</div>
	);
};

export default Consumer(Inventory);
