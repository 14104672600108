import React from "react";
import Helmet from "react-helmet";
import cogoToast from "cogo-toast";

import {Consumer} from "../../context";
import Sidebar from "../../components/Sidebar";
import ConfigSideBar from "../../components/ConfigSideBar";
import Header from "../../components/Header";
import TableUpload from "../../components/Tables/TableUpload";
import Modal from "../../components/modal/DinamicModal";
import ScheduleModal from "../../components/modal/ScheduleModal";
import Paginator from "../../components/Paginator";

import request from "../../tool/request";
import {URL_API} from "../../contants/url_api";
import Controls from "../../components/Controls/Controls";

const Request = new request();

class UpdatePage extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			log: [],
			user: this.props.context.loadUser(),
			schedule: {},
			isOpen: false,
			screen: null,
			loading: false,
			page: 1,
		};
	}

	componentWillMount() {
		this.loadLog();
	}

	async loadLog() {
		const data = {user_id: this.state.user.user_id, type: 2};
		const response = await Request.get(
			URL_API + "/publication/get/log/list",
			data
		);
		if (!response.empty && !response.error) {
			 await this.setState({log: response.data});
		} else {
			await this.setState({log: []});
		}
	}

	async newUpdate() {
		const data = {
			user_id: this.state.user.user_id,
			access_token: this.state.user.access_token,
			refresh_token: this.state.user.refresh_token,
			expire: this.state.user.expire,
		};
		const response = await Request.post(
			URL_API + "/publication/update/data",
			data
		);
		if (response.error) {
			cogoToast.error("No fue posible iniciar la actualización de los datos.", {
				position: "bottom-right",
			});

			return;
		} else {
			cogoToast.loading("Ha iniciado la actualización de los datos.", {
				position: "bottom-right",
			});
			await this.loadLog();
		}
	}

	async updateData(data) {
		await this.setState(data);
	}

	async configHandler() {
		const data = {
			user_id: this.state.user.user_id,
			type: 2,
		};
		const response = await Request.get(URL_API + "/schedule/get", data);

		if (response.error || response.empty) {
			await this.setState({schedule: {}, isOpen: true});
		} else {
			await this.setState({schedule: response.data, isOpen: true});
		}

		let modal = document.getElementById("updateModal");

		if (modal) {
			modal.classList.remove("hide-dinamic-modal");
			modal.classList.add("show-modal");
		}
	}

	async selectPage(page) {
		await this.setState({page: page});
	}

	async saveNewSchedule(event) {
		event.preventDefault();

		const data = {
			...this.state.schedule,
			type: 2,
			user_id: this.state.user.user_id,
			access_token: this.state.user.access_token,
			refresh_token: this.state.user.refresh_token,
			expire: this.state.user.expire,
		};

		const response = await Request.post(URL_API + "/schedule/save", data);

		if (response.error) {
			cogoToast.error("No fue posible guardar la programación.", {
				position: "bottom-right",
			});
		} else {
			cogoToast.success("Programación guardada con exito.", {
				position: "bottom-right",
			});
			await this.setState({schedule: {}, isOpen: false});
			let modal = document.getElementById("updateModal");
			if (modal) {
				modal.classList.add("hide-dinamic-modal");
				modal.classList.remove("show-modal");
			}
		}
	}

	render() {
		return (
			<div className="updated column">
				<Helmet>
					<title>Update | Plataforma de sincronización</title>
				</Helmet>
				<Header />
				<div className="row">
					<div className="column sidebar" id="sidebar">
						<Sidebar />
					</div>
					<div className="column align-center ">
						<div className="row">
							<div className="white-space-24"></div>
						</div>
						<Controls
							icon={"fa-upload"}
							title="Actualizar Datos"
							lblBtn="Actualizar"
							btnHandlerVisible={true}
							btnConfigVisible={true}
							lblConfigBtn={""}
							handler={this.newUpdate.bind(this)}
							configHandler={this.configHandler.bind(this)}
						/>
						<div className="row">
							<div className="white-space-32"></div>
						</div>
						<div className="row justify-center">
							<TableUpload
								dataset={this.state.log}
								isLoading={this.state.loading}
								page={this.state.page}
							/>
						</div>
						<div className="row">
							<div className="white-space-16"></div>
						</div>
						<div className="row">
							<Paginator
								pages={Math.ceil(this.state.log.length / 20)}
								setPage={this.selectPage.bind(this)}
							/>
						</div>
					</div>
				</div>
				<Modal
					idModal={"updateModal"}
					sizeModalContainer={"mini"}
					title={"Programar Actualización"}
					btnTextSuccess={"Guardar"}
					success={this.saveNewSchedule.bind(this)}
					showBtnSuccess={true}
					btnTextCancel={"Cancelar"}
					showBtnCancel={true}
					loadingSave={false}
				>
					<ScheduleModal
						isOpen={this.state.isOpen}
						updateData={this.updateData.bind(this)}
						schedule={this.state.schedule}
					/>
				</Modal>

				<ConfigSideBar />
			</div>
		);
	}
}

export default Consumer(UpdatePage);
