import React from "react";

const ScheduleModal = (props) => {


	return (
		<div className="row full  justify-center align-center">
			<div className="column container justify-center  align-center">
				<div className="row justify-center">
					<div className="column justify-center">
						<p>Archivo: </p>
					</div>
					&nbsp;
					<div className="column align-center justify-center full">
						<input
							className="input text-center input-file padding-normal"
							id="file-input"
							type="file"
							accept=".xls,.xlsx"
							onChange={(event) => {
								props.uploadFile(event);
							}}
						></input>
						<input
							type="text"
							disabled
							className="input padding-normal"
							value={props.fileName}
						/>
					</div>
					<div className="column ">
						<label htmlFor="file-input" className=" label-input-file">
							Elegir
						</label>
					</div>
					<div className="column half">
						<div className="white-space-8"></div>
					</div>
				</div>
				<div className="row">
					<div className="white-space-8"></div>
				</div>
			</div>
		</div>
	);
};
export default ScheduleModal;
