import React from "react";

const Controls = (props) => {

	return (
		<div className="row align-center justify-center row-control">
			<div className="container  align-center justify-start">
				<div className="row-responsive">
					<div className="column justify-center align-start">
						<h2>{props.title}</h2>
					</div>
					<div className="column justify-center align-center">
						<div className="row justify-end align-end">

								<button
									type="button"
									className="btn btn-primary  padding-big btn-small color-white"
									onClick={props.handler}
								>
									<i className={`fas ${props.icon} font-text`} />
									&nbsp; {props.lblBtn}
								</button>

						</div>
					</div>
				</div>
			</div>
		</div>
	);
};
export default Controls;
