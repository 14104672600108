import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import 'react-app-polyfill/ie11';
import WebFont from 'webfontloader';
import WOW from 'wowjs';
import * as serviceWorker from './serviceWorker';
import './sass/style.scss';

/*  WeFont */
WebFont.load({
    google: {
        families: ['Rubik:400,500,700,900', 'sans-serif']
    }
});

new WOW.WOW().init();


/* CSS */


ReactDOM.render(<App />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
