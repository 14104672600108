import React from "react";
import Helmet from "react-helmet";
import cogoToast from "cogo-toast";

import {Consumer} from "../../context";
import request from "../../tool/request";
import {URL_API} from "../../contants/url_api";

import Header from "../../components/Header";
import Sidebar from "../../components/Sidebar";
import ConfigSideBar from "../../components/ConfigSideBar";
import Controls from "../../components/Controls/ControlNew";
import TableInventory from "../../components/Tables/TableStores";
import Paginator from "../../components/Paginator";
import Modal from "../../components/modal/DinamicModal";
import StoreModal from "../../components/modal/StoreModal";

const Request = new request();

class Sucursales extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			log: [],
			user: this.props.context.loadUser(),
			dataset: [],
			loading: false,
			page: 1,
			location: {
				address_line: "",
				latitude: 21.161908,
				longitude: -86.8515279,
			},
			sucursal: {
				id: "0",
				description: "",
				horario: "",
				area_code: "",
				number: "",
				status: "active",
			},
		};
	}

	componentDidMount() {
		this.loadSucursales();
	}

	async selectPage(page) {
		await this.setState({page: page});
	}

	async updateSucursal(data) {
		await this.setState(data);
	}

	async loadSucursales() {
		let data = {user_id: this.state.user.user_id};

		let response = await Request.post(URL_API + "/stores/get/all", data);

		if (response.error || response.empty) {
			await this.setState({dataset: []});
		} else {
			await this.setState({dataset: response.data});
		}
	}

	async newHandler() {
		await this.setState({
			sucursal: {
				id: "0",
				description: "",
				horario: "",
				area_code: "",
				number: "",
				status: "active",
			},
			location: {
				address_line: "",
				latitude: 21.161908,
				longitude: -86.8515279,
			},
		});

		let modal = document.getElementById("storeModal");
		if (modal) {
			modal.classList.remove("hide-dinamic-modal");
			modal.classList.add("show-modal");
		}
	}

	async saveStore(event) {
		event.preventDefault();

		let sucursalData = this.state.sucursal;
		let locationData = this.state.location;

		let url = "";
		let data = {
			user_id: this.state.user.user_id,
			access_token: this.state.user.access_token,
			store: {
				id: sucursalData.id,
				description: sucursalData.description,
				open_hours: sucursalData.horario,
				status: sucursalData.status,
				phone: {
					area_code: sucursalData.area_code,
					number: sucursalData.number,
				},
				location: {
					address_line: locationData.address_line,
					latitude: locationData.latitude,
					longitude: locationData.longitude,
				},
			},
		};

		if (this.state.sucursal.id === "0") {
			url = URL_API + "/stores/create";
		} else {
			url = URL_API + "/stores/update";
		}

		let response = await Request.post(url, data);

		if (response.error) {
			cogoToast.error("No fue posible guardar la sucursal.", {
				position: "bottom-right",
			});
		} else {
			await this.loadSucursales();

			cogoToast.success("Sucursal guardada con exito.", {
				position: "bottom-right",
			});
			let modal = document.getElementById("storeModal");
			if (modal) {
				modal.classList.add("hide-dinamic-modal");
				modal.classList.remove("show-modal");
			}
		}
	}

	async openToEdit(sucursalEdit) {
		await this.setState({
			sucursal: sucursalEdit,
			location: {
				address_line: sucursalEdit.address_line,
				latitude: sucursalEdit.latitude,
				longitude: sucursalEdit.longitude,
			},
		});
		let modal = document.getElementById("storeModal");
		if (modal) {
			modal.classList.remove("hide-dinamic-modal");
			modal.classList.add("show-modal");
		}
	}

	render() {
		return (
			<div className="Home column">
				<Helmet>
					<title>Sucursales | Plataforma de sincronización</title>
				</Helmet>

				<Header />
				<div className="row">
					<div className="column sidebar" id="sidebar">
						<Sidebar />
					</div>

					<div className="column align-center ">
						<div className="row">
							<div className="white-space-24"></div>
						</div>
						<Controls
							icon={"fa-plus"}
							title="Sucursales"
							lblBtn="Nuevo"
							handler={this.newHandler.bind(this)}
						/>
						<div className="row">
							<div className="white-space-32"></div>
						</div>
						<div className="row justify-center align-center">
							<TableInventory
								dataset={this.state.dataset}
								isLoading={this.state.loading}
								page={this.state.page}
								openToEdit={this.openToEdit.bind(this)}
							/>
						</div>
						<div className="row">
							<div className="white-space-16"></div>
						</div>
						<div className="row">
							<Paginator
								pages={Math.ceil(this.state.dataset.length / 20)}
								setPage={this.selectPage.bind(this)}
							/>
						</div>
					</div>
				</div>
				<Modal
					idModal={"storeModal"}
					sizeModalContainer={"big"}
					title={"Sucursal"}
					btnTextSuccess={"Guardar"}
					success={this.saveStore.bind(this)}
					showBtnSuccess={true}
					btnTextCancel={"Cancelar"}
					showBtnCancel={true}
					loadingSave={false}
				>
					<StoreModal
						updateSucursal={this.updateSucursal.bind(this)}
						sucursal={this.state.sucursal}
						location={this.state.location}
					/>
				</Modal>
				<ConfigSideBar />
			</div>
		);
	}
}

export default Consumer(Sucursales);
