import React from "react";

const Controls = (props) => {

	return (
		<div className="row align-center justify-center row-control">
			<div className="container  align-center justify-start">
				<div className="row-responsive">
					<div className="column justify-center align-start">
						<h2>{props.title}</h2>
					</div>
					<div className="column justify-center align-center">
						<div className="row justify-end align-end">
							
								<React.Fragment>
									<input
										className="input padding-big"
										type="text"
										placeholder="Buscar por ID o SKU"
										name="every"
										onKeyDown = { props.keypress }
										onChange={props.inputChange}
										value={props.valueInput}
									></input>
									&nbsp;
									<button
										type="button"
										className="btn btn-primary  padding-big btn-small color-white"
										onClick={props.searchHandler}

									>
										<i className="fas fa-search"></i>
										&nbsp; Buscar
									</button>
								</React.Fragment>
				
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};
export default Controls;
