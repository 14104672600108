import React, {useEffect, useState} from "react";

const TableStores = (props) => {
	const [dataset, setDataset] = useState([]);
	
	useEffect(() => {
		if (dataset !== props.dataset) {
			setDataset(props.dataset);
		}
	}, [props.dataset]);

	return (
		<div className="container">
			<div className="card-table">
				<div className="table-responsive column">
					<table>
						<thead>
							<tr className="text-left">
								<th className="date text-center th-options-small">Nombre</th>
								<th className="date text-center th-options-small">Dirección</th>
								<th className="date text-center th-options-small">Status</th>
								<th className="date text-center th-options-small"></th>
							</tr>
						</thead>
						<tbody>
							{props.isLoading ? (
								<tr>
									<td colSpan="4" className="date text-center th-options-small">
										<i className="fas fa-spinner animation-rotate"></i>
									</td>
								</tr>
							) : dataset.length > 0 ? (
								dataset
								.slice((props.page - 1) * 20, props.page * 20)
								.map((data, key) => {
									return (
										<tr key={key}>
											<td className="date text-center th-options-small">
												{data.description}
											</td>
											<td className="date text-center th-options-small">
												{data.address_line}
											</td>
											<td className="date text-center th-options-small">
												{data.status}
											</td>
											<td className="date text-center"> 

											<button
													className="btn-full btn-mini btn-secondary cursor-pointer btn-transparent"
													type="button "
													onClick     =   {() =>{
														props.openToEdit(data);
													}}
												>
													<i className="fas fa-pen"></i>
												</button>
												

											</td>
										</tr>
									);
								})
							) : (
								<tr>
									<td colSpan="5" className="date text-center th-options-small">
										No Existen registros
									</td>
								</tr>
							)}
						</tbody>
					</table>
				</div>
			</div>
		</div>
	);
};

export default TableStores;
