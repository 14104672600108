

import React from 'react';
import Routes from '../routes';

const View = function () {
    return (
        <Routes></Routes>
    );
}

export default View;