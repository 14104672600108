import React, {useEffect, useState} from "react";
import TimeField from "react-simple-timefield";

const ScheduleModal = (props) => {
	const [prevProps, setPrevProps] = useState(props);
	const [schedule, setSchedule] = useState(props.schedule);

	useEffect(() => {
		if (prevProps.isOpen !== props.isOpen) {
			if (props.schedule !== prevProps.schedule) {
				setSchedule(props.schedule);
			}
		}
		setPrevProps(props);
	}, [props.isOpen,props.schedule]);

	async function updateData(data) {
		await props.updateData({schedule: data});
		setSchedule(data);
	}

	return (
		<div className="row full  justify-center align-center">
			<div className="column container justify-center  align-center">
				<div className="row justify-center">
					<div className="column justify-center">
						<p>Hora inicio:</p>
					</div>
					<div className="column align-center justify-center">
						<TimeField
							name="horario_desde"
							value={schedule.desde ? schedule.desde : ""}
							onChange={(event, value) => {
								updateData({...schedule, desde: value});
							}}
							input={<input type="text" className="input text-center padding-normal"></input>}
						/>
					</div>
					<div className="column half">
						<div className="white-space-8"></div>
					</div>
				</div>
				<div className="row">
					<div className="white-space-8"></div>
				</div>
				<div className="row">
					<div className="column justify-center">
						<p>Hora fin:</p>
					</div>
					<div className="column">
						<TimeField
							name="horario_hasta"
							value={schedule.hasta ? schedule.hasta : ""}
							onChange={(event, value) => {
								updateData({...schedule, hasta: value});
							}}
							input={<input type="text" className="input text-center padding-normal"></input>}
						/>
					</div>
					<div className="column half">
						<div className="white-space-8"></div>
					</div>
				</div>
				<div className="row">
					<div className="white-space-8"></div>
				</div>
				<div className="row full justify-center">
					<div className="column justify-center">
						<p>Cada:</p>
					</div>
					<div className="column full">
						<input
							type="number"
							name="every"
							className="input text-center padding-normal"
							value={schedule.frecuencia ? schedule.frecuencia : 0}
							min={1}
							onChange={(event) => {
								updateData({...schedule, frecuencia: event.target.value});
							}}
						></input>
					</div>
					<div className="column half justify-center align-center">
						<p>Horas</p>
					</div>
				</div>
			</div>
		</div>
	);
};
export default ScheduleModal;
