import React from "react";
import Helmet from "react-helmet";
import {withRouter} from "react-router-dom";
import {Consumer} from "../../context";
import {URL_API} from "../../contants/url_api";
import Request from "../../tool/request";
import Logo from "../../image/logo_no_bg.png";
import Card from "../../components/Card";

class Login extends React.Component {
	constructor(props) {
		super(props);
		this.setState = {};
	}

	componentDidMount() {
		this.load();
	}

	async load() {
		const vars = {};
		window.location.href.replace(/[?&]+([^=&]+)=([^&]*)/gi, function (m, key, value) {
			vars[key] = value;
		});

		if (vars.code) {
			const pst = new Request();
			const data = {code: vars.code};

			const token = await pst.post(URL_API + "/users/generatetoken", data);
			if (token) {
				if (token.usuario.error) {
					console.log("Error", token);
					return;
				} else {
					this.props.context.setUser({...token.usuario});
					window.location = "/";
				}
			}
		}
	}

	async hanldeSubmit(e) {
		e.preventDefault();
		const req = new Request();
		const response = await req.post(URL_API + "/users/login");
		if (response) {
			if (response.error) {
				return;
			}
			window.location = response.redirect;
		}
	}

	render() {
		return (
			<div className="Login column justify-center align-center">
				<Helmet>
					<title>Login | Plataforma de sincronización</title>
				</Helmet>
				<div className="row align-center justify-center">
					<div className="column justify-center align-center">
						<Card className="justify-center align-center">
							<div className="container">
								<form action="" className="" onSubmit={this.hanldeSubmit.bind(this)}>
									<div className="row responsive-img justify-center align-center">
										<div className="container-img logo">
											<img src={Logo} alt="Logo" />
										</div>
									</div>

									<div className="row">
										<div className="white-space-16"></div>
									</div>
									<div className="row full justify-center align-center">
										<button
											type="submit"
											className="btn btn-primary btn-login padding-big color-white"
											value="Submit"
										>
											<i className="fas fa-sign-in-alt"></i> &nbsp; Login
										</button>
									</div>
								</form>
							</div>
						</Card>
					</div>
				</div>
			</div>
		);
	}
}
export default withRouter(Consumer(Login));
