import React, {useState, useEffect} from "react";
import ImageLoader from "react-loading-image";

import Loading from "../../image/load.gif";
import NoAvalible from "../../image/no-image-available.png";

const ModalZoom = (props) => {
	const [imgZoom, setImgZoom] = useState("");

	useEffect(() => {
		setImgZoom(props.imgZoom);
	}, [props.imgZoom]);

	function close(event) {
		event.preventDefault();
		props.closeModal("");
		const modal = document.getElementById("image-zoom-modal");
		modal.classList.remove("show-zoom-modal");
		modal.classList.add("hide-zoom-modal");
	}

	return (
		<div className="image-zoom-modal align-center justify-center hide-zoom-modal" id="image-zoom-modal">
			<div className="modal-content-zoom column align-center">
				<div className="column full">
					<div className="row full justify-end">
						<button id="modal-close-zoom" onClick={close}>
							&times;
						</button>
					</div>
				</div>
				<div className="container justify-center align-center">
					<ImageLoader
						id="img-zoom"
						className="img-zoom"
						src={imgZoom}
						image={() => <img src={imgZoom} alt="Imagen" />}
						loading={() => <img src={Loading} alt="Cargando..." />}
						error={() => <img src={NoAvalible} alt="Cargando..." />}
					/>
				</div>
			</div>
		</div>
	);
};

export default ModalZoom;
