import xl from "excel4node"; //  para crear el

function isFloat(n) {
    return n === +n && n !== (n|0);
}

function isInteger(n) {
    return n === +n && n === (n|0);
}

async function exportToExcel(dataset,exclude) {
	try {
		let columns = Object.keys(dataset[0]),
			rows = dataset;

		columns = columns.filter((col) => !exclude.includes(col) );

		//Inicializa el documento de excel
		const wb = new xl.Workbook({
			dateFormat: "dd/mm/yyyy HH:mm:ss",
		});
		//Inicializa la Hoja de Calculo
		const ws = wb.addWorksheet("Listado");
		//Estilo para las columnas
		var style = wb.createStyle({
			fill: {
				type: "pattern",
				patternType: "solid",
				bgColor: "#0061b6",
				fgColor: "#0061b6",
			},
			font: {
				bold: true,
				color: "fbfbfb",
			},
		});
		//Pintar Información a excel
		columns.forEach((column, colIndex) => {
			ws.column(colIndex + 1).setWidth(20);
			ws.cell(1, colIndex + 1)
				.string(column)
				.style(style);

			rows.forEach((row, rowIndex) => {
				const startRow = 2;
				if(isFloat(row[column]) || isInteger(row[column])) {
                    ws.cell(rowIndex + startRow, colIndex + 1)
                    .number(row[column]);
                } else {
					ws.cell(rowIndex + startRow, colIndex + 1).string(row[column]);
				}
			});
		});

		// Archivo de Excel en Buffer
		const response = await new Promise((res) => {
			wb.writeToBuffer().then(function (buffer) {
				res(buffer);
			});
		});

		return response;
	} catch (error) {
		console.log(error);
		return {error: true};
	}
}

export default exportToExcel;
