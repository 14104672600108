import React from "react";
import {Consumer} from "../context";
import {Link} from "react-router-dom";
import useWindowDimensions from "../tool/DimesionHook";

const Header = (props) => {
	const { width } = useWindowDimensions();

	return (
		<header className="justify-center header-slide">
			<div className="row full">
				<div className="options row align-center justify-center">
					<div className="logo align-center">
						{width < 576 ? (
							<a
								onClick={() => {
									const leftbar = document.getElementById("sidebar");
									if (leftbar) {
										leftbar.classList.toggle("open");
									}
								}}
								href="#settings"
								className="font-regular color-white menubar"
							>
								<i className="fas fa-bars font-regular color-white"></i>
							</a>
						) : (
							<Link to="/" className="responsive-img auto">
								<i className="fas fa-home font-regular color-white"></i>
							</Link>
						)}
					</div>
				</div>

				<div className="row justify-end align-center justify-center">
					<div className="nav-item auto ">
						<span className="font-medium color-white pad padding-right-8">
							{`${props.context.user.first_name} ${props.context.user.last_name}`}
						</span>
						<a
							onClick={() => {
								const leftbar = document.getElementById("rightsidebar");
								if (leftbar) {
									leftbar.classList.toggle("open");
								}
							}}
							href="#settings"
							className="font-regular color-white"
						>
							<i className="fas fa-cogs font-text" />
						</a>
					</div>
				</div>
			</div>
		</header>
	);
};
export default Consumer(Header);
