import React, {useEffect, useState} from "react";

const TableImage = (props) => {
	const [imgList, setImgList] = useState([]);

	useEffect(() => {
		if (imgList !== props.dataset) {
			setImgList(props.dataset);
		}
	}, [props.dataset]);

	function printImage(pictures) {
		let array = [];

		pictures.map((pic, key) => {
			array.push(
				<td key={key} className="cursor-pointer">
					<img key={key} src={pic.url || pic.source} alt="" onClick={()=>{props.openZoom(pic.url || pic.source)}} />
				</td>
			);
		});

		if (array.length < 10) {
			for (let index = 0; array.length < 10; index++) {
				array.push(<td key={Math.ceil(Math.random() * 10000)}></td>);
			}
		}

		return array;
	}

	return (
		<div className="container">
			<div className="card-table">
				<div className="table-responsive column">
					<table>
						<thead>
							<tr className="text-left">
								<th className="date text-center th-options-small">ID</th>
								<th className="date text-center th-options-small">SKU</th>
								<th className="date text-center th-options-small">Descripción</th>
								<th className="date text-center th-options-small">Img1</th>
								<th className="date text-center th-options-small">Img2</th>
								<th className="date text-center th-options-small">Img3</th>
								<th className="date text-center th-options-small">Img4</th>
								<th className="date text-center th-options-small">Img5</th>
								<th className="date text-center th-options-small">Img6</th>
								<th className="date text-center th-options-small">Img7</th>
								<th className="date text-center th-options-small">Img8</th>
								<th className="date text-center th-options-small">Img9</th>
								<th className="date text-center th-options-small">Img10</th>
							</tr>
						</thead>
						<tbody>
							{props.isLoading ? (
								<tr>
									<td colSpan="13" className="date text-center th-options-small">
										<i className="fas fa-spinner animation-rotate"></i>
									</td>
								</tr>
							) : imgList.length > 0 ? (
								imgList.slice((props.page - 1) * 20, props.page * 20).map((image, key) => {
									return (
										<tr key={key}>
											<td className="date text-center th-options-small  font-mini">{image.id_publicacion}</td>
											<td className="date text-center th-options font-mini">{image.SELLER_SKU}</td>
											<td className="date text-left   th-options font-mini">{image.title}</td>
											{printImage(image.pictures)}
										</tr>
									);
								})
							) : (
								<tr>
									<td colSpan="13" className="date text-center th-options-small">
										No Existen registros
									</td>
								</tr>
							)}
						</tbody>
					</table>
				</div>
			</div>
		</div>
	);
};

export default TableImage;
