import React from 'react';
import View from './views/view';

function App() {
  return (
    <div className="App">
      <View></View>
    </div>
  );
}

export default App;
