import React, {useEffect, useState} from "react";
import Map from "../maps/map";

const StoreModal = (props) => {
	const [prevProps, setPrevProps] = useState(props);
	const [sucursal, setSucursal] = useState(props.sucursal);
	const [location, setLocation] = useState(props.location);

	useEffect(() => {
		if (props.sucursal !== prevProps.sucursal) {
			setSucursal(props.sucursal);
		}
		if (props.location !== prevProps.location) {
			setLocation(props.location);
		}
		setPrevProps(props);
	}, [props.sucursal,props.location]);

	async function updateData(data) {
		props.updateSucursal({sucursal: {...sucursal, ...data}});
		setSucursal({...sucursal, ...data});
	}

	async function updateLocation(data) {
		props.updateSucursal({location: {...location, ...data}});
		setLocation({...location, ...data});
	}

	return (
		<div className="full  justify-center align-center">
			<div className="row-responsive container">
				<div className="column">
					<div className="container-side-form ">
						<div className="row">
							<p>Nombre</p>
						</div>
						<div className="row">
							<input
								type="text"
								className="input full padding-normal"
								value={sucursal ? sucursal.description : ""}
								required
								onChange={(event) => {
									updateData({description: event.target.value});
								}}
								maxLength={60}
							></input>
						</div>
						<div className="row">
							<div className="white-space-8"></div>
						</div>
						<div className="row">
							<p>Dirección</p>
						</div>
						<div className="row">
							<input
								type="text"
								placeholder="Calle - No."
								value={sucursal ? location.address_line : ""}
								required
								onChange={(event) => {
									updateLocation({address_line: event.target.value});
								}}
								className="input full padding-normal"
								maxLength={60}
							></input>
							<button
								type="button"
								id="validateGPS"
								className="btn btn-primary btn-small color-white"
							>
								<i className="fas fa-map-marker-alt"></i> Validar
							</button>
						</div>
						<div className="row">
							<div className="white-space-8"></div>
						</div>
						<div className="row">
							<p>Horario</p>
						</div>
						<div className="row">
							<input
								type="text"
								value={sucursal ? sucursal.horario : ""}
								required
								onChange={(event) => {
									updateData({horario: event.target.value});
								}}
								className="input full padding-normal"
								maxLength={100}
							></input>
						</div>
						<div className="row">
							<div className="white-space-8"></div>
						</div>
						<div className="row">
							<p>Teléfono</p>
						</div>
						<div className="row">
							<div className="column half">
								<input
									type="number"
									placeholder="Área"
									className="input full padding-normal"
									required
									value={sucursal ? sucursal.area_code: ""}
									onChange={(event) => {
										updateData({area_code:  event.target.value});
									}}
									maxLength={5}
								></input>
							</div>
							<div className="column">
								<input
									type="number"
									placeholder="Número"
									className="input full padding-normal"
									maxLength={10}
									required
									value={sucursal ? sucursal.number: ""}
									onChange={(event) => {
										updateData({number:  event.target.value});
									}}
								></input>
							</div>
						</div>
						<div className="row">
							<div className="white-space-8"></div>
						</div>
						<div className="row">
							<p>Status</p>
						</div>
						<div className="row">
							<select
								className="input full padding-normal"
								value={sucursal ? sucursal.status: ""}
								onChange={(event) => {
									updateData({status: event.target.value});
								}}
								required
							>
								<option value="active">Activo</option>
								<option value="paused">Pausada</option>
							</select>
						</div>

						<div className="row">
							<div className="white-space-16"></div>
						</div>
					</div>
				</div>
				<div className="column map-side justify-center  align-center">
					<div className="row justify-center">
						<Map
							address={location ? location.address_line : ""}
							setAddress={updateLocation.bind(this)}
							validateGPS={document.getElementById("validateGPS")}
						/>
					</div>
				</div>
			</div>
		</div>
	);
};
export default StoreModal;
