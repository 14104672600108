import React, {useEffect, useState} from "react";
import NumberFormat from "react-number-format";

const TableInventory = (props) => {
	const [dataset, setDataset] = useState([]);
	
	useEffect(() => {
		if (dataset !== props.dataset) {
			setDataset(props.dataset);
		}
	}, [props.dataset]);

	return (
		<div className="container">
			<div className="card-table">
				<div className="table-responsive column">
					<table>
						<thead>
							<tr className="text-left">
								<th className="date text-center th-options-small">Clave</th>
								<th className="date text-center th-options-small">SKU</th>
								<th className="date text-center th-options-small">Stock</th>
								<th className="date text-center th-options-small">Precio</th>
								<th className="date text-center th-options-small">Sucursal</th>
							</tr>
						</thead>
						<tbody>
							{props.isLoading ? (
								<tr>
									<td colSpan="5" className="date text-center th-options-small">
										<i className="fas fa-spinner animation-rotate"></i>
									</td>
								</tr>
							) : dataset.length > 0 ? (
								dataset
								.slice((props.page - 1) * 20, props.page * 20)
								.map((data, key) => {
									return (
										<tr key={key}>
											<td className="date text-center th-options-small">
												{data.Clave}
											</td>
											<td className="date text-center th-options-small">
												{data.SKU}
											</td>
											<td className="date text-center th-options-small">
												{data.Stock}
											</td>
											<td className="date text-right th-options-small">
												<NumberFormat
													value={data.Precio}
													displayType={"text"}
													thousandSeparator={true}
													prefix={"$"}
													decimalScale={2}
													fixedDecimalScale={true}
												/>
											</td>
											<td className="date text-center">{data.Sucursal} </td>
										</tr>
									);
								})
							) : (
								<tr>
									<td colSpan="5" className="date text-center th-options-small">
										No Existen registros
									</td>
								</tr>
							)}
						</tbody>
					</table>
				</div>
			</div>
		</div>
	);
};

export default TableInventory;
