import React from "react";
import {Consumer} from "../context";

const ConfigSideBar = (props) => {

    function handleLogout() {
        props.context.logOut();
    }

	return (
		<div id="rightsidebar" className="rightsidebar row">
			<div className="column container">
				<div className="white-space-24"></div>
				<div className="accordion justify-center ">
					<a
						href="#logout"
						className="font-regular color-white nav-exit"
						onClick={handleLogout}
					>
						<i className="fas fa-sign-out-alt font-text " /> Cerrar Sesión
					</a>
				</div>
			</div>
		</div>
	);
};
export default Consumer(ConfigSideBar);
