
const request  = require("superagent");

class Request {
  get(url, data) {
    const result = request
      .get(url)
      .query(data)
      .then(res => {
        return res.body;
      })
      .catch(err => {
        console.log(err.message);
        return { error: true, message: 'Error de Conexión - Vuelve a Intentarlo' };
	  });

	return result;
  }

  post(url, data) {
    const result = request
      .post(url)
      .send(data)
      .then(res => {
        return res.body
      })
      .catch(err => {
        console.log(err.message);
        return { error: true, message: 'Error de Conexión - Vuelve a Intentarlo' };
	  });

	return result;
  }
}

export default Request;
